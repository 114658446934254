:root {
  --actual-white:  #eff8ff;
  --navy-blue: #161f64;
  --deep-blue: #343e83;
  --velvet-blue: #4283ad;
  --off-white: #efe0e3;
  --light-purple: #c592bb;
  --blue-purple: #492a68;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  max-width: 100vw;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  background-color: var(--off-white);
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
  font-family: "Playfair Display", serif;
  display: flex;
  justify-content: center;
  align-items: center;
}



