/*the stars in the background*/
.theStars {
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

#stars {
    width: 1px;
    height: 1px;
    background: transparent;
    animation: animStar 50s linear infinite;
    box-shadow: 779px 1331px #161f64, 324px 42px #161f64, 303px 586px #161f64,
    1312px 276px #161f64, 451px 625px #161f64, 521px 1931px #161f64, 1087px 1871px #161f64,
    36px 1546px #161f64, 132px 934px #161f64, 1698px 901px #161f64, 1418px 664px #161f64,
    1448px 1157px #161f64, 1084px 232px #161f64, 347px 1776px #161f64, 1722px 243px #161f64,
    1629px 835px #161f64, 479px 969px #161f64, 1231px 960px #161f64, 586px 384px #161f64,
    164px 527px #161f64, 8px 646px #161f64, 1150px 1126px #161f64, 665px 1357px #161f64,
    1556px 1982px #161f64, 1260px 1961px #161f64, 1675px 1741px #161f64,
    1843px 1514px #161f64, 718px 1628px #161f64, 242px 1343px #161f64, 1497px 1880px #161f64,
    1364px 230px #161f64, 1739px 1302px #161f64, 636px 959px #161f64, 304px 686px #161f64,
    614px 751px #161f64, 1935px 816px #161f64, 1428px 60px #161f64, 355px 335px #161f64,
    1594px 158px #161f64, 90px 60px #161f64, 1553px 162px #161f64, 1239px 1825px #161f64,
    1945px 587px #161f64, 749px 1785px #161f64, 1987px 1172px #161f64, 1301px 1237px #161f64,
    1039px 342px #161f64, 1585px 1481px #161f64, 995px 1048px #161f64, 524px 932px #161f64,
    214px 413px #161f64, 1701px 1300px #161f64, 1037px 1613px #161f64, 1871px 996px #161f64,
    1360px 1635px #161f64, 1110px 1313px #161f64, 412px 1783px #161f64, 1949px 177px #161f64,
    903px 1854px #161f64, 700px 1936px #161f64, 378px 125px #161f64, 308px 834px #161f64,
    1118px 962px #161f64, 1350px 1929px #161f64, 781px 1811px #161f64, 561px 137px #161f64,
    757px 1148px #161f64, 1670px 1979px #161f64, 343px 739px #161f64, 945px 795px #161f64,
    576px 1903px #161f64, 1078px 1436px #161f64, 1583px 450px #161f64, 1366px 474px #161f64,
    297px 1873px #161f64, 192px 162px #161f64, 1624px 1633px #161f64, 59px 453px #161f64,
    82px 1872px #161f64, 1933px 498px #161f64, 1966px 1974px #161f64, 1975px 1688px #161f64,
    779px 314px #161f64, 1858px 1543px #161f64, 73px 1507px #161f64, 1693px 975px #161f64,
    1683px 108px #161f64, 1768px 1654px #161f64, 654px 14px #161f64, 494px 171px #161f64,
    1689px 1895px #161f64, 1660px 263px #161f64, 1031px 903px #161f64, 1203px 1393px #161f64,
    1333px 1421px #161f64, 1113px 41px #161f64, 1206px 1645px #161f64, 1325px 1635px #161f64,
    142px 388px #161f64, 572px 215px #161f64, 1535px 296px #161f64, 1419px 407px #161f64,
    1379px 1003px #161f64, 329px 469px #161f64, 1791px 1652px #161f64, 935px 1802px #161f64,
    1330px 1820px #161f64, 421px 1933px #161f64, 828px 365px #161f64, 275px 316px #161f64,
    707px 960px #161f64, 1605px 1554px #161f64, 625px 58px #161f64, 717px 1697px #161f64,
    1669px 246px #161f64, 1925px 322px #161f64, 1154px 1803px #161f64, 1929px 295px #161f64,
    1248px 240px #161f64, 1045px 1755px #161f64, 166px 942px #161f64, 1888px 1773px #161f64,
    678px 1963px #161f64, 1370px 569px #161f64, 1974px 1400px #161f64, 1786px 460px #161f64,
    51px 307px #161f64, 784px 1400px #161f64, 730px 1258px #161f64, 1712px 393px #161f64,
    416px 170px #161f64, 1797px 1932px #161f64, 572px 219px #161f64, 1557px 1856px #161f64,
    218px 8px #161f64, 348px 1334px #161f64, 469px 413px #161f64, 385px 1738px #161f64,
    1357px 1818px #161f64, 240px 942px #161f64, 248px 1847px #161f64, 1535px 806px #161f64,
    236px 1514px #161f64, 1429px 1556px #161f64, 73px 1633px #161f64, 1398px 1121px #161f64,
    671px 1301px #161f64, 1404px 1663px #161f64, 740px 1018px #161f64, 1600px 377px #161f64,
    785px 514px #161f64, 112px 1084px #161f64, 1915px 1887px #161f64, 1463px 1848px #161f64,
    687px 1115px #161f64, 1268px 1768px #161f64, 1729px 1425px #161f64,
    1284px 1022px #161f64, 801px 974px #161f64, 1975px 1317px #161f64, 1354px 834px #161f64,
    1446px 1484px #161f64, 1283px 1786px #161f64, 11px 523px #161f64, 1842px 236px #161f64,
    1355px 654px #161f64, 429px 7px #161f64, 1033px 1128px #161f64, 157px 297px #161f64,
    545px 635px #161f64, 52px 1080px #161f64, 827px 1520px #161f64, 1121px 490px #161f64,
    9px 309px #161f64, 1744px 1586px #161f64, 1014px 417px #161f64, 1534px 524px #161f64,
    958px 552px #161f64, 1403px 1496px #161f64, 387px 703px #161f64, 1522px 548px #161f64,
    1355px 282px #161f64, 1532px 601px #161f64, 1838px 790px #161f64, 290px 259px #161f64,
    295px 598px #161f64, 1601px 539px #161f64, 1561px 1272px #161f64, 34px 1922px #161f64,
    1024px 543px #161f64, 467px 369px #161f64, 722px 333px #161f64, 1976px 1255px #161f64,
    766px 983px #161f64, 1582px 1285px #161f64, 12px 512px #161f64, 617px 1410px #161f64,
    682px 577px #161f64, 1334px 1438px #161f64, 439px 327px #161f64, 1617px 1661px #161f64,
    673px 129px #161f64, 794px 941px #161f64, 1386px 1902px #161f64, 37px 1353px #161f64,
    1467px 1353px #161f64, 416px 18px #161f64, 187px 344px #161f64, 200px 1898px #161f64,
    1491px 1619px #161f64, 811px 347px #161f64, 924px 1827px #161f64, 945px 217px #161f64,
    1735px 1228px #161f64, 379px 1890px #161f64, 79px 761px #161f64, 825px 1837px #161f64,
    1980px 1558px #161f64, 1308px 1573px #161f64, 1488px 1726px #161f64,
    382px 1208px #161f64, 522px 595px #161f64, 1277px 1898px #161f64, 354px 552px #161f64,
    161px 1784px #161f64, 614px 251px #161f64, 526px 1576px #161f64, 17px 212px #161f64,
    179px 996px #161f64, 467px 1208px #161f64, 1944px 1838px #161f64, 1140px 1093px #161f64,
    858px 1007px #161f64, 200px 1064px #161f64, 423px 1964px #161f64, 1945px 439px #161f64,
    1377px 689px #161f64, 1120px 1437px #161f64, 1876px 668px #161f64, 907px 1324px #161f64,
    343px 1976px #161f64, 1816px 1501px #161f64, 1849px 177px #161f64, 647px 91px #161f64,
    1984px 1012px #161f64, 1336px 1300px #161f64, 128px 648px #161f64, 305px 1060px #161f64,
    1324px 826px #161f64, 1263px 1314px #161f64, 1801px 629px #161f64, 1614px 1555px #161f64,
    1634px 90px #161f64, 1603px 452px #161f64, 891px 1984px #161f64, 1556px 1906px #161f64,
    121px 68px #161f64, 1676px 1714px #161f64, 516px 936px #161f64, 1947px 1492px #161f64,
    1455px 1519px #161f64, 45px 602px #161f64, 205px 1039px #161f64, 793px 172px #161f64,
    1562px 1739px #161f64, 1056px 110px #161f64, 1512px 379px #161f64, 1795px 1621px #161f64,
    1848px 607px #161f64, 262px 1719px #161f64, 477px 991px #161f64, 483px 883px #161f64,
    1239px 1197px #161f64, 1496px 647px #161f64, 1649px 25px #161f64, 1491px 1946px #161f64,
    119px 996px #161f64, 179px 1472px #161f64, 1341px 808px #161f64, 1565px 1700px #161f64,
    407px 1544px #161f64, 1754px 357px #161f64, 1288px 981px #161f64, 902px 1997px #161f64,
    1755px 1668px #161f64, 186px 877px #161f64, 1202px 1882px #161f64, 461px 1213px #161f64,
    1400px 748px #161f64, 1969px 1899px #161f64, 809px 522px #161f64, 514px 1219px #161f64,
    374px 275px #161f64, 938px 1973px #161f64, 357px 552px #161f64, 144px 1722px #161f64,
    1572px 912px #161f64, 402px 1858px #161f64, 1544px 1195px #161f64, 667px 1257px #161f64,
    727px 1496px #161f64, 993px 232px #161f64, 1772px 313px #161f64, 1040px 1590px #161f64,
    1204px 1973px #161f64, 1268px 79px #161f64, 1555px 1048px #161f64, 986px 1707px #161f64,
    978px 1710px #161f64, 713px 360px #161f64, 407px 863px #161f64, 461px 736px #161f64,
    284px 1608px #161f64, 103px 430px #161f64, 1283px 1319px #161f64, 977px 1186px #161f64,
    1966px 1516px #161f64, 1287px 1129px #161f64, 70px 1098px #161f64, 1189px 889px #161f64,
    1126px 1734px #161f64, 309px 1292px #161f64, 879px 764px #161f64, 65px 473px #161f64,
    1003px 1959px #161f64, 658px 791px #161f64, 402px 1576px #161f64, 35px 622px #161f64,
    529px 1589px #161f64, 164px 666px #161f64, 1876px 1290px #161f64, 1541px 526px #161f64,
    270px 1297px #161f64, 440px 865px #161f64, 1500px 802px #161f64, 182px 1754px #161f64,
    1264px 892px #161f64, 272px 1249px #161f64, 1289px 1535px #161f64, 190px 1646px #161f64,
    955px 242px #161f64, 1456px 1597px #161f64, 1727px 1983px #161f64, 635px 801px #161f64,
    226px 455px #161f64, 1396px 1710px #161f64, 849px 1863px #161f64, 237px 1264px #161f64,
    839px 140px #161f64, 1122px 735px #161f64, 1280px 15px #161f64, 1318px 242px #161f64,
    1819px 1148px #161f64, 333px 1392px #161f64, 1949px 553px #161f64, 1878px 1332px #161f64,
    467px 548px #161f64, 1812px 1082px #161f64, 1067px 193px #161f64, 243px 156px #161f64,
    483px 1616px #161f64, 1714px 933px #161f64, 759px 1800px #161f64, 1822px 995px #161f64,
    1877px 572px #161f64, 581px 1084px #161f64, 107px 732px #161f64, 642px 1837px #161f64,
    166px 1493px #161f64, 1555px 198px #161f64, 819px 307px #161f64, 947px 345px #161f64,
    827px 224px #161f64, 927px 1394px #161f64, 540px 467px #161f64, 1093px 405px #161f64,
    1140px 927px #161f64, 130px 529px #161f64, 33px 1980px #161f64, 1147px 1663px #161f64,
    1616px 1436px #161f64, 528px 710px #161f64, 798px 1100px #161f64, 505px 1480px #161f64,
    899px 641px #161f64, 1909px 1949px #161f64, 1311px 964px #161f64, 979px 1301px #161f64,
    1393px 969px #161f64, 1793px 1886px #161f64, 292px 357px #161f64, 1196px 1718px #161f64,
    1290px 1994px #161f64, 537px 1973px #161f64, 1181px 1674px #161f64,
    1740px 1566px #161f64, 1307px 265px #161f64, 922px 522px #161f64, 1892px 472px #161f64,
    384px 1746px #161f64, 392px 1098px #161f64, 647px 548px #161f64, 390px 1498px #161f64,
    1246px 138px #161f64, 730px 876px #161f64, 192px 1472px #161f64, 1790px 1789px #161f64,
    928px 311px #161f64, 1253px 1647px #161f64, 747px 1921px #161f64, 1561px 1025px #161f64,
    1533px 1292px #161f64, 1985px 195px #161f64, 728px 729px #161f64, 1712px 1936px #161f64,
    512px 1717px #161f64, 1528px 483px #161f64, 313px 1642px #161f64, 281px 1849px #161f64,
    1212px 799px #161f64, 435px 1191px #161f64, 1422px 611px #161f64, 1718px 1964px #161f64,
    411px 944px #161f64, 210px 636px #161f64, 1502px 1295px #161f64, 1434px 349px #161f64,
    769px 60px #161f64, 747px 1053px #161f64, 789px 504px #161f64, 1436px 1264px #161f64,
    1893px 1225px #161f64, 1394px 1788px #161f64, 1108px 1317px #161f64,
    1673px 1395px #161f64, 854px 1010px #161f64, 1705px 80px #161f64, 1858px 148px #161f64,
    1729px 344px #161f64, 1388px 664px #161f64, 895px 406px #161f64, 1479px 157px #161f64,
    1441px 1157px #161f64, 552px 1900px #161f64, 516px 364px #161f64, 1647px 189px #161f64,
    1427px 1071px #161f64, 785px 729px #161f64, 1080px 1710px #161f64, 504px 204px #161f64,
    1177px 1622px #161f64, 657px 34px #161f64, 1296px 1099px #161f64, 248px 180px #161f64,
    1212px 1568px #161f64, 667px 1562px #161f64, 695px 841px #161f64, 1608px 1247px #161f64,
    751px 882px #161f64, 87px 167px #161f64, 607px 1368px #161f64, 1363px 1203px #161f64,
    1836px 317px #161f64, 1668px 1703px #161f64, 830px 1154px #161f64, 1721px 1398px #161f64,
    1601px 1280px #161f64, 976px 874px #161f64, 1743px 254px #161f64, 1020px 1815px #161f64,
    1670px 1766px #161f64, 1890px 735px #161f64, 1379px 136px #161f64, 1864px 695px #161f64,
    206px 965px #161f64, 1404px 1932px #161f64, 1923px 1360px #161f64, 247px 682px #161f64,
    519px 1708px #161f64, 645px 750px #161f64, 1164px 1204px #161f64, 834px 323px #161f64,
    172px 1350px #161f64, 213px 972px #161f64, 1837px 190px #161f64, 285px 1806px #161f64,
    1047px 1299px #161f64, 1548px 825px #161f64, 1730px 324px #161f64, 1346px 1909px #161f64,
    772px 270px #161f64, 345px 1190px #161f64, 478px 1433px #161f64, 1479px 25px #161f64,
    1994px 1830px #161f64, 1744px 732px #161f64, 20px 1635px #161f64, 690px 1795px #161f64,
    1594px 569px #161f64, 579px 245px #161f64, 1398px 733px #161f64, 408px 1352px #161f64,
    1774px 120px #161f64, 1152px 1370px #161f64, 1698px 1810px #161f64, 710px 1450px #161f64,
    665px 286px #161f64, 493px 1720px #161f64, 786px 5px #161f64, 637px 1140px #161f64,
    764px 324px #161f64, 927px 310px #161f64, 938px 1424px #161f64, 1884px 744px #161f64,
    913px 462px #161f64, 1831px 1936px #161f64, 1527px 249px #161f64, 36px 1381px #161f64,
    1597px 581px #161f64, 1530px 355px #161f64, 949px 459px #161f64, 799px 828px #161f64,
    242px 1471px #161f64, 654px 797px #161f64, 796px 594px #161f64, 1365px 678px #161f64,
    752px 23px #161f64, 1630px 541px #161f64, 982px 72px #161f64, 1733px 1831px #161f64,
    21px 412px #161f64, 775px 998px #161f64, 335px 1945px #161f64, 264px 583px #161f64,
    158px 1311px #161f64, 528px 164px #161f64, 1978px 574px #161f64, 717px 1203px #161f64,
    734px 1591px #161f64, 1555px 820px #161f64, 16px 1943px #161f64, 1625px 1177px #161f64,
    1236px 690px #161f64, 1585px 1590px #161f64, 1737px 1728px #161f64, 721px 698px #161f64,
    1804px 1186px #161f64, 166px 980px #161f64, 1850px 230px #161f64, 330px 1712px #161f64,
    95px 797px #161f64, 1948px 1078px #161f64, 469px 939px #161f64, 1269px 1899px #161f64,
    955px 1220px #161f64, 1137px 1075px #161f64, 312px 1293px #161f64, 986px 1762px #161f64,
    1103px 1238px #161f64, 428px 1993px #161f64, 355px 570px #161f64, 977px 1836px #161f64,
    1395px 1092px #161f64, 276px 913px #161f64, 1743px 656px #161f64, 773px 502px #161f64,
    1686px 1322px #161f64, 1516px 1945px #161f64, 1334px 501px #161f64, 266px 156px #161f64,
    455px 655px #161f64, 798px 72px #161f64, 1059px 1259px #161f64, 1402px 1687px #161f64,
    236px 1329px #161f64, 1455px 786px #161f64, 146px 1228px #161f64, 1851px 823px #161f64,
    1062px 100px #161f64, 1220px 953px #161f64, 20px 1826px #161f64, 36px 1063px #161f64,
    1525px 338px #161f64, 790px 1521px #161f64, 741px 1099px #161f64, 288px 1489px #161f64,
    700px 1060px #161f64, 390px 1071px #161f64, 411px 1036px #161f64, 1853px 1072px #161f64,
    1446px 1085px #161f64, 1164px 874px #161f64, 924px 925px #161f64, 291px 271px #161f64,
    1257px 1964px #161f64, 1580px 1352px #161f64, 1507px 1216px #161f64, 211px 956px #161f64,
    985px 1195px #161f64, 975px 1640px #161f64, 518px 101px #161f64, 663px 1395px #161f64,
    914px 532px #161f64, 145px 1320px #161f64, 69px 1397px #161f64, 982px 523px #161f64,
    257px 725px #161f64, 1599px 831px #161f64, 1636px 1513px #161f64, 1250px 1158px #161f64,
    1132px 604px #161f64, 183px 102px #161f64, 1057px 318px #161f64, 1247px 1835px #161f64,
    1983px 1110px #161f64, 1077px 1455px #161f64, 921px 1770px #161f64, 806px 1350px #161f64,
    1938px 1992px #161f64, 855px 1260px #161f64, 902px 1345px #161f64, 658px 1908px #161f64,
    1845px 679px #161f64, 712px 1482px #161f64, 595px 950px #161f64, 1784px 1992px #161f64,
    1847px 1785px #161f64, 691px 1004px #161f64, 175px 1179px #161f64, 1666px 1911px #161f64,
    41px 61px #161f64, 971px 1080px #161f64, 1830px 1450px #161f64, 1351px 1518px #161f64,
    1257px 99px #161f64, 1395px 1498px #161f64, 1117px 252px #161f64, 1779px 597px #161f64,
    1346px 729px #161f64, 1108px 1144px #161f64, 402px 691px #161f64, 72px 496px #161f64,
    1673px 1604px #161f64, 1497px 974px #161f64, 1865px 1664px #161f64, 88px 806px #161f64,
    918px 77px #161f64, 244px 1118px #161f64, 256px 1820px #161f64, 1851px 1840px #161f64,
    605px 1851px #161f64, 634px 383px #161f64, 865px 37px #161f64, 943px 1024px #161f64,
    1951px 177px #161f64, 1097px 523px #161f64, 985px 1700px #161f64, 1243px 122px #161f64,
    768px 1070px #161f64, 468px 194px #161f64, 320px 1867px #161f64, 1850px 185px #161f64,
    380px 1616px #161f64, 468px 1294px #161f64, 1122px 1743px #161f64, 884px 299px #161f64,
    1300px 1917px #161f64, 1860px 396px #161f64, 1270px 990px #161f64, 529px 733px #161f64,
    1975px 1347px #161f64, 1885px 685px #161f64, 226px 506px #161f64, 651px 878px #161f64,
    1323px 680px #161f64, 1284px 680px #161f64, 238px 1967px #161f64, 911px 174px #161f64,
    1111px 521px #161f64, 1150px 85px #161f64, 794px 502px #161f64, 484px 1856px #161f64,
    1809px 368px #161f64, 112px 953px #161f64, 590px 1009px #161f64, 1655px 311px #161f64,
    100px 1026px #161f64, 1803px 352px #161f64, 865px 306px #161f64, 1077px 1019px #161f64,
    1335px 872px #161f64, 1647px 1298px #161f64, 1233px 1387px #161f64, 698px 1036px #161f64,
    659px 1860px #161f64, 388px 1412px #161f64, 1212px 458px #161f64, 755px 1468px #161f64,
    696px 1654px #161f64, 1144px 60px #161f64;
}

#stars:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 779px 1331px #161f64, 324px 42px #161f64, 303px 586px #161f64,
    1312px 276px #161f64, 451px 625px #161f64, 521px 1931px #161f64, 1087px 1871px #161f64,
    36px 1546px #161f64, 132px 934px #161f64, 1698px 901px #161f64, 1418px 664px #161f64,
    1448px 1157px #161f64, 1084px 232px #161f64, 347px 1776px #161f64, 1722px 243px #161f64,
    1629px 835px #161f64, 479px 969px #161f64, 1231px 960px #161f64, 586px 384px #161f64,
    164px 527px #161f64, 8px 646px #161f64, 1150px 1126px #161f64, 665px 1357px #161f64,
    1556px 1982px #161f64, 1260px 1961px #161f64, 1675px 1741px #161f64,
    1843px 1514px #161f64, 718px 1628px #161f64, 242px 1343px #161f64, 1497px 1880px #161f64,
    1364px 230px #161f64, 1739px 1302px #161f64, 636px 959px #161f64, 304px 686px #161f64,
    614px 751px #161f64, 1935px 816px #161f64, 1428px 60px #161f64, 355px 335px #161f64,
    1594px 158px #161f64, 90px 60px #161f64, 1553px 162px #161f64, 1239px 1825px #161f64,
    1945px 587px #161f64, 749px 1785px #161f64, 1987px 1172px #161f64, 1301px 1237px #161f64,
    1039px 342px #161f64, 1585px 1481px #161f64, 995px 1048px #161f64, 524px 932px #161f64,
    214px 413px #161f64, 1701px 1300px #161f64, 1037px 1613px #161f64, 1871px 996px #161f64,
    1360px 1635px #161f64, 1110px 1313px #161f64, 412px 1783px #161f64, 1949px 177px #161f64,
    903px 1854px #161f64, 700px 1936px #161f64, 378px 125px #161f64, 308px 834px #161f64,
    1118px 962px #161f64, 1350px 1929px #161f64, 781px 1811px #161f64, 561px 137px #161f64,
    757px 1148px #161f64, 1670px 1979px #161f64, 343px 739px #161f64, 945px 795px #161f64,
    576px 1903px #161f64, 1078px 1436px #161f64, 1583px 450px #161f64, 1366px 474px #161f64,
    297px 1873px #161f64, 192px 162px #161f64, 1624px 1633px #161f64, 59px 453px #161f64,
    82px 1872px #161f64, 1933px 498px #161f64, 1966px 1974px #161f64, 1975px 1688px #161f64,
    779px 314px #161f64, 1858px 1543px #161f64, 73px 1507px #161f64, 1693px 975px #161f64,
    1683px 108px #161f64, 1768px 1654px #161f64, 654px 14px #161f64, 494px 171px #161f64,
    1689px 1895px #161f64, 1660px 263px #161f64, 1031px 903px #161f64, 1203px 1393px #161f64,
    1333px 1421px #161f64, 1113px 41px #161f64, 1206px 1645px #161f64, 1325px 1635px #161f64,
    142px 388px #161f64, 572px 215px #161f64, 1535px 296px #161f64, 1419px 407px #161f64,
    1379px 1003px #161f64, 329px 469px #161f64, 1791px 1652px #161f64, 935px 1802px #161f64,
    1330px 1820px #161f64, 421px 1933px #161f64, 828px 365px #161f64, 275px 316px #161f64,
    707px 960px #161f64, 1605px 1554px #161f64, 625px 58px #161f64, 717px 1697px #161f64,
    1669px 246px #161f64, 1925px 322px #161f64, 1154px 1803px #161f64, 1929px 295px #161f64,
    1248px 240px #161f64, 1045px 1755px #161f64, 166px 942px #161f64, 1888px 1773px #161f64,
    678px 1963px #161f64, 1370px 569px #161f64, 1974px 1400px #161f64, 1786px 460px #161f64,
    51px 307px #161f64, 784px 1400px #161f64, 730px 1258px #161f64, 1712px 393px #161f64,
    416px 170px #161f64, 1797px 1932px #161f64, 572px 219px #161f64, 1557px 1856px #161f64,
    218px 8px #161f64, 348px 1334px #161f64, 469px 413px #161f64, 385px 1738px #161f64,
    1357px 1818px #161f64, 240px 942px #161f64, 248px 1847px #161f64, 1535px 806px #161f64,
    236px 1514px #161f64, 1429px 1556px #161f64, 73px 1633px #161f64, 1398px 1121px #161f64,
    671px 1301px #161f64, 1404px 1663px #161f64, 740px 1018px #161f64, 1600px 377px #161f64,
    785px 514px #161f64, 112px 1084px #161f64, 1915px 1887px #161f64, 1463px 1848px #161f64,
    687px 1115px #161f64, 1268px 1768px #161f64, 1729px 1425px #161f64,
    1284px 1022px #161f64, 801px 974px #161f64, 1975px 1317px #161f64, 1354px 834px #161f64,
    1446px 1484px #161f64, 1283px 1786px #161f64, 11px 523px #161f64, 1842px 236px #161f64,
    1355px 654px #161f64, 429px 7px #161f64, 1033px 1128px #161f64, 157px 297px #161f64,
    545px 635px #161f64, 52px 1080px #161f64, 827px 1520px #161f64, 1121px 490px #161f64,
    9px 309px #161f64, 1744px 1586px #161f64, 1014px 417px #161f64, 1534px 524px #161f64,
    958px 552px #161f64, 1403px 1496px #161f64, 387px 703px #161f64, 1522px 548px #161f64,
    1355px 282px #161f64, 1532px 601px #161f64, 1838px 790px #161f64, 290px 259px #161f64,
    295px 598px #161f64, 1601px 539px #161f64, 1561px 1272px #161f64, 34px 1922px #161f64,
    1024px 543px #161f64, 467px 369px #161f64, 722px 333px #161f64, 1976px 1255px #161f64,
    766px 983px #161f64, 1582px 1285px #161f64, 12px 512px #161f64, 617px 1410px #161f64,
    682px 577px #161f64, 1334px 1438px #161f64, 439px 327px #161f64, 1617px 1661px #161f64,
    673px 129px #161f64, 794px 941px #161f64, 1386px 1902px #161f64, 37px 1353px #161f64,
    1467px 1353px #161f64, 416px 18px #161f64, 187px 344px #161f64, 200px 1898px #161f64,
    1491px 1619px #161f64, 811px 347px #161f64, 924px 1827px #161f64, 945px 217px #161f64,
    1735px 1228px #161f64, 379px 1890px #161f64, 79px 761px #161f64, 825px 1837px #161f64,
    1980px 1558px #161f64, 1308px 1573px #161f64, 1488px 1726px #161f64,
    382px 1208px #161f64, 522px 595px #161f64, 1277px 1898px #161f64, 354px 552px #161f64,
    161px 1784px #161f64, 614px 251px #161f64, 526px 1576px #161f64, 17px 212px #161f64,
    179px 996px #161f64, 467px 1208px #161f64, 1944px 1838px #161f64, 1140px 1093px #161f64,
    858px 1007px #161f64, 200px 1064px #161f64, 423px 1964px #161f64, 1945px 439px #161f64,
    1377px 689px #161f64, 1120px 1437px #161f64, 1876px 668px #161f64, 907px 1324px #161f64,
    343px 1976px #161f64, 1816px 1501px #161f64, 1849px 177px #161f64, 647px 91px #161f64,
    1984px 1012px #161f64, 1336px 1300px #161f64, 128px 648px #161f64, 305px 1060px #161f64,
    1324px 826px #161f64, 1263px 1314px #161f64, 1801px 629px #161f64, 1614px 1555px #161f64,
    1634px 90px #161f64, 1603px 452px #161f64, 891px 1984px #161f64, 1556px 1906px #161f64,
    121px 68px #161f64, 1676px 1714px #161f64, 516px 936px #161f64, 1947px 1492px #161f64,
    1455px 1519px #161f64, 45px 602px #161f64, 205px 1039px #161f64, 793px 172px #161f64,
    1562px 1739px #161f64, 1056px 110px #161f64, 1512px 379px #161f64, 1795px 1621px #161f64,
    1848px 607px #161f64, 262px 1719px #161f64, 477px 991px #161f64, 483px 883px #161f64,
    1239px 1197px #161f64, 1496px 647px #161f64, 1649px 25px #161f64, 1491px 1946px #161f64,
    119px 996px #161f64, 179px 1472px #161f64, 1341px 808px #161f64, 1565px 1700px #161f64,
    407px 1544px #161f64, 1754px 357px #161f64, 1288px 981px #161f64, 902px 1997px #161f64,
    1755px 1668px #161f64, 186px 877px #161f64, 1202px 1882px #161f64, 461px 1213px #161f64,
    1400px 748px #161f64, 1969px 1899px #161f64, 809px 522px #161f64, 514px 1219px #161f64,
    374px 275px #161f64, 938px 1973px #161f64, 357px 552px #161f64, 144px 1722px #161f64,
    1572px 912px #161f64, 402px 1858px #161f64, 1544px 1195px #161f64, 667px 1257px #161f64,
    727px 1496px #161f64, 993px 232px #161f64, 1772px 313px #161f64, 1040px 1590px #161f64,
    1204px 1973px #161f64, 1268px 79px #161f64, 1555px 1048px #161f64, 986px 1707px #161f64,
    978px 1710px #161f64, 713px 360px #161f64, 407px 863px #161f64, 461px 736px #161f64,
    284px 1608px #161f64, 103px 430px #161f64, 1283px 1319px #161f64, 977px 1186px #161f64,
    1966px 1516px #161f64, 1287px 1129px #161f64, 70px 1098px #161f64, 1189px 889px #161f64,
    1126px 1734px #161f64, 309px 1292px #161f64, 879px 764px #161f64, 65px 473px #161f64,
    1003px 1959px #161f64, 658px 791px #161f64, 402px 1576px #161f64, 35px 622px #161f64,
    529px 1589px #161f64, 164px 666px #161f64, 1876px 1290px #161f64, 1541px 526px #161f64,
    270px 1297px #161f64, 440px 865px #161f64, 1500px 802px #161f64, 182px 1754px #161f64,
    1264px 892px #161f64, 272px 1249px #161f64, 1289px 1535px #161f64, 190px 1646px #161f64,
    955px 242px #161f64, 1456px 1597px #161f64, 1727px 1983px #161f64, 635px 801px #161f64,
    226px 455px #161f64, 1396px 1710px #161f64, 849px 1863px #161f64, 237px 1264px #161f64,
    839px 140px #161f64, 1122px 735px #161f64, 1280px 15px #161f64, 1318px 242px #161f64,
    1819px 1148px #161f64, 333px 1392px #161f64, 1949px 553px #161f64, 1878px 1332px #161f64,
    467px 548px #161f64, 1812px 1082px #161f64, 1067px 193px #161f64, 243px 156px #161f64,
    483px 1616px #161f64, 1714px 933px #161f64, 759px 1800px #161f64, 1822px 995px #161f64,
    1877px 572px #161f64, 581px 1084px #161f64, 107px 732px #161f64, 642px 1837px #161f64,
    166px 1493px #161f64, 1555px 198px #161f64, 819px 307px #161f64, 947px 345px #161f64,
    827px 224px #161f64, 927px 1394px #161f64, 540px 467px #161f64, 1093px 405px #161f64,
    1140px 927px #161f64, 130px 529px #161f64, 33px 1980px #161f64, 1147px 1663px #161f64,
    1616px 1436px #161f64, 528px 710px #161f64, 798px 1100px #161f64, 505px 1480px #161f64,
    899px 641px #161f64, 1909px 1949px #161f64, 1311px 964px #161f64, 979px 1301px #161f64,
    1393px 969px #161f64, 1793px 1886px #161f64, 292px 357px #161f64, 1196px 1718px #161f64,
    1290px 1994px #161f64, 537px 1973px #161f64, 1181px 1674px #161f64,
    1740px 1566px #161f64, 1307px 265px #161f64, 922px 522px #161f64, 1892px 472px #161f64,
    384px 1746px #161f64, 392px 1098px #161f64, 647px 548px #161f64, 390px 1498px #161f64,
    1246px 138px #161f64, 730px 876px #161f64, 192px 1472px #161f64, 1790px 1789px #161f64,
    928px 311px #161f64, 1253px 1647px #161f64, 747px 1921px #161f64, 1561px 1025px #161f64,
    1533px 1292px #161f64, 1985px 195px #161f64, 728px 729px #161f64, 1712px 1936px #161f64,
    512px 1717px #161f64, 1528px 483px #161f64, 313px 1642px #161f64, 281px 1849px #161f64,
    1212px 799px #161f64, 435px 1191px #161f64, 1422px 611px #161f64, 1718px 1964px #161f64,
    411px 944px #161f64, 210px 636px #161f64, 1502px 1295px #161f64, 1434px 349px #161f64,
    769px 60px #161f64, 747px 1053px #161f64, 789px 504px #161f64, 1436px 1264px #161f64,
    1893px 1225px #161f64, 1394px 1788px #161f64, 1108px 1317px #161f64,
    1673px 1395px #161f64, 854px 1010px #161f64, 1705px 80px #161f64, 1858px 148px #161f64,
    1729px 344px #161f64, 1388px 664px #161f64, 895px 406px #161f64, 1479px 157px #161f64,
    1441px 1157px #161f64, 552px 1900px #161f64, 516px 364px #161f64, 1647px 189px #161f64,
    1427px 1071px #161f64, 785px 729px #161f64, 1080px 1710px #161f64, 504px 204px #161f64,
    1177px 1622px #161f64, 657px 34px #161f64, 1296px 1099px #161f64, 248px 180px #161f64,
    1212px 1568px #161f64, 667px 1562px #161f64, 695px 841px #161f64, 1608px 1247px #161f64,
    751px 882px #161f64, 87px 167px #161f64, 607px 1368px #161f64, 1363px 1203px #161f64,
    1836px 317px #161f64, 1668px 1703px #161f64, 830px 1154px #161f64, 1721px 1398px #161f64,
    1601px 1280px #161f64, 976px 874px #161f64, 1743px 254px #161f64, 1020px 1815px #161f64,
    1670px 1766px #161f64, 1890px 735px #161f64, 1379px 136px #161f64, 1864px 695px #161f64,
    206px 965px #161f64, 1404px 1932px #161f64, 1923px 1360px #161f64, 247px 682px #161f64,
    519px 1708px #161f64, 645px 750px #161f64, 1164px 1204px #161f64, 834px 323px #161f64,
    172px 1350px #161f64, 213px 972px #161f64, 1837px 190px #161f64, 285px 1806px #161f64,
    1047px 1299px #161f64, 1548px 825px #161f64, 1730px 324px #161f64, 1346px 1909px #161f64,
    772px 270px #161f64, 345px 1190px #161f64, 478px 1433px #161f64, 1479px 25px #161f64,
    1994px 1830px #161f64, 1744px 732px #161f64, 20px 1635px #161f64, 690px 1795px #161f64,
    1594px 569px #161f64, 579px 245px #161f64, 1398px 733px #161f64, 408px 1352px #161f64,
    1774px 120px #161f64, 1152px 1370px #161f64, 1698px 1810px #161f64, 710px 1450px #161f64,
    665px 286px #161f64, 493px 1720px #161f64, 786px 5px #161f64, 637px 1140px #161f64,
    764px 324px #161f64, 927px 310px #161f64, 938px 1424px #161f64, 1884px 744px #161f64,
    913px 462px #161f64, 1831px 1936px #161f64, 1527px 249px #161f64, 36px 1381px #161f64,
    1597px 581px #161f64, 1530px 355px #161f64, 949px 459px #161f64, 799px 828px #161f64,
    242px 1471px #161f64, 654px 797px #161f64, 796px 594px #161f64, 1365px 678px #161f64,
    752px 23px #161f64, 1630px 541px #161f64, 982px 72px #161f64, 1733px 1831px #161f64,
    21px 412px #161f64, 775px 998px #161f64, 335px 1945px #161f64, 264px 583px #161f64,
    158px 1311px #161f64, 528px 164px #161f64, 1978px 574px #161f64, 717px 1203px #161f64,
    734px 1591px #161f64, 1555px 820px #161f64, 16px 1943px #161f64, 1625px 1177px #161f64,
    1236px 690px #161f64, 1585px 1590px #161f64, 1737px 1728px #161f64, 721px 698px #161f64,
    1804px 1186px #161f64, 166px 980px #161f64, 1850px 230px #161f64, 330px 1712px #161f64,
    95px 797px #161f64, 1948px 1078px #161f64, 469px 939px #161f64, 1269px 1899px #161f64,
    955px 1220px #161f64, 1137px 1075px #161f64, 312px 1293px #161f64, 986px 1762px #161f64,
    1103px 1238px #161f64, 428px 1993px #161f64, 355px 570px #161f64, 977px 1836px #161f64,
    1395px 1092px #161f64, 276px 913px #161f64, 1743px 656px #161f64, 773px 502px #161f64,
    1686px 1322px #161f64, 1516px 1945px #161f64, 1334px 501px #161f64, 266px 156px #161f64,
    455px 655px #161f64, 798px 72px #161f64, 1059px 1259px #161f64, 1402px 1687px #161f64,
    236px 1329px #161f64, 1455px 786px #161f64, 146px 1228px #161f64, 1851px 823px #161f64,
    1062px 100px #161f64, 1220px 953px #161f64, 20px 1826px #161f64, 36px 1063px #161f64,
    1525px 338px #161f64, 790px 1521px #161f64, 741px 1099px #161f64, 288px 1489px #161f64,
    700px 1060px #161f64, 390px 1071px #161f64, 411px 1036px #161f64, 1853px 1072px #161f64,
    1446px 1085px #161f64, 1164px 874px #161f64, 924px 925px #161f64, 291px 271px #161f64,
    1257px 1964px #161f64, 1580px 1352px #161f64, 1507px 1216px #161f64, 211px 956px #161f64,
    985px 1195px #161f64, 975px 1640px #161f64, 518px 101px #161f64, 663px 1395px #161f64,
    914px 532px #161f64, 145px 1320px #161f64, 69px 1397px #161f64, 982px 523px #161f64,
    257px 725px #161f64, 1599px 831px #161f64, 1636px 1513px #161f64, 1250px 1158px #161f64,
    1132px 604px #161f64, 183px 102px #161f64, 1057px 318px #161f64, 1247px 1835px #161f64,
    1983px 1110px #161f64, 1077px 1455px #161f64, 921px 1770px #161f64, 806px 1350px #161f64,
    1938px 1992px #161f64, 855px 1260px #161f64, 902px 1345px #161f64, 658px 1908px #161f64,
    1845px 679px #161f64, 712px 1482px #161f64, 595px 950px #161f64, 1784px 1992px #161f64,
    1847px 1785px #161f64, 691px 1004px #161f64, 175px 1179px #161f64, 1666px 1911px #161f64,
    41px 61px #161f64, 971px 1080px #161f64, 1830px 1450px #161f64, 1351px 1518px #161f64,
    1257px 99px #161f64, 1395px 1498px #161f64, 1117px 252px #161f64, 1779px 597px #161f64,
    1346px 729px #161f64, 1108px 1144px #161f64, 402px 691px #161f64, 72px 496px #161f64,
    1673px 1604px #161f64, 1497px 974px #161f64, 1865px 1664px #161f64, 88px 806px #161f64,
    918px 77px #161f64, 244px 1118px #161f64, 256px 1820px #161f64, 1851px 1840px #161f64,
    605px 1851px #161f64, 634px 383px #161f64, 865px 37px #161f64, 943px 1024px #161f64,
    1951px 177px #161f64, 1097px 523px #161f64, 985px 1700px #161f64, 1243px 122px #161f64,
    768px 1070px #161f64, 468px 194px #161f64, 320px 1867px #161f64, 1850px 185px #161f64,
    380px 1616px #161f64, 468px 1294px #161f64, 1122px 1743px #161f64, 884px 299px #161f64,
    1300px 1917px #161f64, 1860px 396px #161f64, 1270px 990px #161f64, 529px 733px #161f64,
    1975px 1347px #161f64, 1885px 685px #161f64, 226px 506px #161f64, 651px 878px #161f64,
    1323px 680px #161f64, 1284px 680px #161f64, 238px 1967px #161f64, 911px 174px #161f64,
    1111px 521px #161f64, 1150px 85px #161f64, 794px 502px #161f64, 484px 1856px #161f64,
    1809px 368px #161f64, 112px 953px #161f64, 590px 1009px #161f64, 1655px 311px #161f64,
    100px 1026px #161f64, 1803px 352px #161f64, 865px 306px #161f64, 1077px 1019px #161f64,
    1335px 872px #161f64, 1647px 1298px #161f64, 1233px 1387px #161f64, 698px 1036px #161f64,
    659px 1860px #161f64, 388px 1412px #161f64, 1212px 458px #161f64, 755px 1468px #161f64,
    696px 1654px #161f64, 1144px 60px #161f64;
}

#stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    animation: animStar 100s linear infinite;
    box-shadow: 1448px 320px #161f64, 1775px 1663px #161f64, 332px 1364px #161f64,
    878px 340px #161f64, 569px 1832px #161f64, 1422px 1684px #161f64, 1946px 1907px #161f64,
    121px 979px #161f64, 1044px 1069px #161f64, 463px 381px #161f64, 423px 112px #161f64,
    523px 1179px #161f64, 779px 654px #161f64, 1398px 694px #161f64, 1085px 1464px #161f64,
    1599px 1869px #161f64, 801px 1882px #161f64, 779px 1231px #161f64, 552px 932px #161f64,
    1057px 1196px #161f64, 282px 1280px #161f64, 496px 1986px #161f64, 1833px 1120px #161f64,
    1802px 1293px #161f64, 6px 1696px #161f64, 412px 1902px #161f64, 605px 438px #161f64,
    24px 1212px #161f64, 234px 1320px #161f64, 544px 344px #161f64, 1107px 170px #161f64,
    1603px 196px #161f64, 905px 648px #161f64, 68px 1458px #161f64, 649px 1969px #161f64,
    744px 675px #161f64, 1127px 478px #161f64, 714px 1814px #161f64, 1486px 526px #161f64,
    270px 1636px #161f64, 1931px 149px #161f64, 1807px 378px #161f64, 8px 390px #161f64,
    1415px 699px #161f64, 1473px 1211px #161f64, 1590px 141px #161f64, 270px 1705px #161f64,
    69px 1423px #161f64, 1108px 1053px #161f64, 1946px 128px #161f64, 371px 371px #161f64,
    1490px 220px #161f64, 357px 1885px #161f64, 363px 363px #161f64, 1896px 1256px #161f64,
    1979px 1050px #161f64, 947px 1342px #161f64, 1754px 242px #161f64, 514px 974px #161f64,
    65px 1477px #161f64, 1840px 547px #161f64, 950px 695px #161f64, 459px 1150px #161f64,
    1124px 1502px #161f64, 481px 940px #161f64, 680px 839px #161f64, 797px 1169px #161f64,
    1977px 1491px #161f64, 734px 1724px #161f64, 210px 298px #161f64, 816px 628px #161f64,
    686px 770px #161f64, 1721px 267px #161f64, 1663px 511px #161f64, 1481px 1141px #161f64,
    582px 248px #161f64, 1308px 953px #161f64, 628px 657px #161f64, 897px 1535px #161f64,
    270px 931px #161f64, 791px 467px #161f64, 1336px 1732px #161f64, 1013px 1653px #161f64,
    1911px 956px #161f64, 587px 816px #161f64, 83px 456px #161f64, 930px 1478px #161f64,
    1587px 1694px #161f64, 614px 1200px #161f64, 302px 1782px #161f64, 1711px 1432px #161f64,
    443px 904px #161f64, 1666px 714px #161f64, 1588px 1167px #161f64, 273px 1075px #161f64,
    1679px 461px #161f64, 721px 664px #161f64, 1202px 10px #161f64, 166px 1126px #161f64,
    331px 1628px #161f64, 430px 1565px #161f64, 1585px 509px #161f64, 640px 38px #161f64,
    822px 837px #161f64, 1760px 1664px #161f64, 1122px 1458px #161f64, 398px 131px #161f64,
    689px 285px #161f64, 460px 652px #161f64, 1627px 365px #161f64, 348px 1648px #161f64,
    819px 1946px #161f64, 981px 1917px #161f64, 323px 76px #161f64, 979px 684px #161f64,
    887px 536px #161f64, 1348px 1596px #161f64, 1055px 666px #161f64, 1402px 1797px #161f64,
    1300px 1055px #161f64, 937px 238px #161f64, 1474px 1815px #161f64, 1144px 1710px #161f64,
    1629px 1087px #161f64, 911px 919px #161f64, 771px 819px #161f64, 403px 720px #161f64,
    163px 736px #161f64, 1062px 238px #161f64, 1774px 818px #161f64, 1874px 1178px #161f64,
    1177px 699px #161f64, 1244px 1244px #161f64, 1371px 58px #161f64, 564px 1515px #161f64,
    1824px 487px #161f64, 929px 702px #161f64, 394px 1348px #161f64, 1161px 641px #161f64,
    219px 1841px #161f64, 358px 941px #161f64, 140px 1759px #161f64, 1019px 1345px #161f64,
    274px 436px #161f64, 1433px 1605px #161f64, 1798px 1426px #161f64, 294px 1848px #161f64,
    1681px 1877px #161f64, 1344px 1824px #161f64, 1439px 1632px #161f64,
    161px 1012px #161f64, 1308px 588px #161f64, 1789px 582px #161f64, 721px 1910px #161f64,
    318px 218px #161f64, 607px 319px #161f64, 495px 535px #161f64, 1552px 1575px #161f64,
    1562px 67px #161f64, 403px 926px #161f64, 1096px 1800px #161f64, 1814px 1709px #161f64,
    1882px 1831px #161f64, 533px 46px #161f64, 823px 969px #161f64, 530px 165px #161f64,
    1030px 352px #161f64, 1681px 313px #161f64, 338px 115px #161f64, 1607px 211px #161f64,
    1718px 1184px #161f64, 1589px 659px #161f64, 278px 355px #161f64, 464px 1464px #161f64,
    1165px 277px #161f64, 950px 694px #161f64, 1746px 293px #161f64, 793px 911px #161f64,
    528px 773px #161f64, 1883px 1694px #161f64, 748px 182px #161f64, 1924px 1531px #161f64,
    100px 636px #161f64, 1473px 1445px #161f64, 1264px 1244px #161f64, 850px 1377px #161f64,
    987px 1976px #161f64, 933px 1761px #161f64, 922px 1270px #161f64, 500px 396px #161f64,
    1324px 8px #161f64, 1967px 1814px #161f64, 1072px 1401px #161f64, 961px 37px #161f64,
    156px 81px #161f64, 1915px 502px #161f64, 1076px 1846px #161f64, 152px 1669px #161f64,
    986px 1529px #161f64, 1667px 1137px #161f64;
}

#stars2:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1448px 320px #161f64, 1775px 1663px #161f64, 332px 1364px #161f64,
    878px 340px #161f64, 569px 1832px #161f64, 1422px 1684px #161f64, 1946px 1907px #161f64,
    121px 979px #161f64, 1044px 1069px #161f64, 463px 381px #161f64, 423px 112px #161f64,
    523px 1179px #161f64, 779px 654px #161f64, 1398px 694px #161f64, 1085px 1464px #161f64,
    1599px 1869px #161f64, 801px 1882px #161f64, 779px 1231px #161f64, 552px 932px #161f64,
    1057px 1196px #161f64, 282px 1280px #161f64, 496px 1986px #161f64, 1833px 1120px #161f64,
    1802px 1293px #161f64, 6px 1696px #161f64, 412px 1902px #161f64, 605px 438px #161f64,
    24px 1212px #161f64, 234px 1320px #161f64, 544px 344px #161f64, 1107px 170px #161f64,
    1603px 196px #161f64, 905px 648px #161f64, 68px 1458px #161f64, 649px 1969px #161f64,
    744px 675px #161f64, 1127px 478px #161f64, 714px 1814px #161f64, 1486px 526px #161f64,
    270px 1636px #161f64, 1931px 149px #161f64, 1807px 378px #161f64, 8px 390px #161f64,
    1415px 699px #161f64, 1473px 1211px #161f64, 1590px 141px #161f64, 270px 1705px #161f64,
    69px 1423px #161f64, 1108px 1053px #161f64, 1946px 128px #161f64, 371px 371px #161f64,
    1490px 220px #161f64, 357px 1885px #161f64, 363px 363px #161f64, 1896px 1256px #161f64,
    1979px 1050px #161f64, 947px 1342px #161f64, 1754px 242px #161f64, 514px 974px #161f64,
    65px 1477px #161f64, 1840px 547px #161f64, 950px 695px #161f64, 459px 1150px #161f64,
    1124px 1502px #161f64, 481px 940px #161f64, 680px 839px #161f64, 797px 1169px #161f64,
    1977px 1491px #161f64, 734px 1724px #161f64, 210px 298px #161f64, 816px 628px #161f64,
    686px 770px #161f64, 1721px 267px #161f64, 1663px 511px #161f64, 1481px 1141px #161f64,
    582px 248px #161f64, 1308px 953px #161f64, 628px 657px #161f64, 897px 1535px #161f64,
    270px 931px #161f64, 791px 467px #161f64, 1336px 1732px #161f64, 1013px 1653px #161f64,
    1911px 956px #161f64, 587px 816px #161f64, 83px 456px #161f64, 930px 1478px #161f64,
    1587px 1694px #161f64, 614px 1200px #161f64, 302px 1782px #161f64, 1711px 1432px #161f64,
    443px 904px #161f64, 1666px 714px #161f64, 1588px 1167px #161f64, 273px 1075px #161f64,
    1679px 461px #161f64, 721px 664px #161f64, 1202px 10px #161f64, 166px 1126px #161f64,
    331px 1628px #161f64, 430px 1565px #161f64, 1585px 509px #161f64, 640px 38px #161f64,
    822px 837px #161f64, 1760px 1664px #161f64, 1122px 1458px #161f64, 398px 131px #161f64,
    689px 285px #161f64, 460px 652px #161f64, 1627px 365px #161f64, 348px 1648px #161f64,
    819px 1946px #161f64, 981px 1917px #161f64, 323px 76px #161f64, 979px 684px #161f64,
    887px 536px #161f64, 1348px 1596px #161f64, 1055px 666px #161f64, 1402px 1797px #161f64,
    1300px 1055px #161f64, 937px 238px #161f64, 1474px 1815px #161f64, 1144px 1710px #161f64,
    1629px 1087px #161f64, 911px 919px #161f64, 771px 819px #161f64, 403px 720px #161f64,
    163px 736px #161f64, 1062px 238px #161f64, 1774px 818px #161f64, 1874px 1178px #161f64,
    1177px 699px #161f64, 1244px 1244px #161f64, 1371px 58px #161f64, 564px 1515px #161f64,
    1824px 487px #161f64, 929px 702px #161f64, 394px 1348px #161f64, 1161px 641px #161f64,
    219px 1841px #161f64, 358px 941px #161f64, 140px 1759px #161f64, 1019px 1345px #161f64,
    274px 436px #161f64, 1433px 1605px #161f64, 1798px 1426px #161f64, 294px 1848px #161f64,
    1681px 1877px #161f64, 1344px 1824px #161f64, 1439px 1632px #161f64,
    161px 1012px #161f64, 1308px 588px #161f64, 1789px 582px #161f64, 721px 1910px #161f64,
    318px 218px #161f64, 607px 319px #161f64, 495px 535px #161f64, 1552px 1575px #161f64,
    1562px 67px #161f64, 403px 926px #161f64, 1096px 1800px #161f64, 1814px 1709px #161f64,
    1882px 1831px #161f64, 533px 46px #161f64, 823px 969px #161f64, 530px 165px #161f64,
    1030px 352px #161f64, 1681px 313px #161f64, 338px 115px #161f64, 1607px 211px #161f64,
    1718px 1184px #161f64, 1589px 659px #161f64, 278px 355px #161f64, 464px 1464px #161f64,
    1165px 277px #161f64, 950px 694px #161f64, 1746px 293px #161f64, 793px 911px #161f64,
    528px 773px #161f64, 1883px 1694px #161f64, 748px 182px #161f64, 1924px 1531px #161f64,
    100px 636px #161f64, 1473px 1445px #161f64, 1264px 1244px #161f64, 850px 1377px #161f64,
    987px 1976px #161f64, 933px 1761px #161f64, 922px 1270px #161f64, 500px 396px #161f64,
    1324px 8px #161f64, 1967px 1814px #161f64, 1072px 1401px #161f64, 961px 37px #161f64,
    156px 81px #161f64, 1915px 502px #161f64, 1076px 1846px #161f64, 152px 1669px #161f64,
    986px 1529px #161f64, 1667px 1137px #161f64;
}

#stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    animation: animStar 150s linear infinte;
    box-shadow: 387px 1878px #161f64, 760px 1564px #161f64, 1487px 999px #161f64,
    948px 1828px #161f64, 1977px 1001px #161f64, 1284px 1963px #161f64, 656px 284px #161f64,
    1268px 1635px #161f64, 1820px 598px #161f64, 642px 1900px #161f64, 296px 57px #161f64,
    921px 1620px #161f64, 476px 1858px #161f64, 658px 613px #161f64, 1171px 1363px #161f64,
    1419px 283px #161f64, 1037px 731px #161f64, 503px 663px #161f64, 1562px 463px #161f64,
    383px 1197px #161f64, 1171px 1233px #161f64, 876px 1768px #161f64, 856px 1615px #161f64,
    1375px 1924px #161f64, 1725px 918px #161f64, 952px 119px #161f64, 768px 1212px #161f64,
    992px 1462px #161f64, 1929px 717px #161f64, 1947px 755px #161f64, 1818px 1123px #161f64,
    1896px 1672px #161f64, 460px 198px #161f64, 256px 271px #161f64, 752px 544px #161f64,
    1222px 1859px #161f64, 1851px 443px #161f64, 313px 1858px #161f64, 709px 446px #161f64,
    1546px 697px #161f64, 674px 1155px #161f64, 1112px 130px #161f64, 355px 1790px #161f64,
    1496px 974px #161f64, 1696px 480px #161f64, 1316px 1265px #161f64, 1645px 1063px #161f64,
    1182px 237px #161f64, 427px 1582px #161f64, 859px 253px #161f64, 458px 939px #161f64,
    1517px 1644px #161f64, 1943px 60px #161f64, 212px 1650px #161f64, 966px 1786px #161f64,
    473px 712px #161f64, 130px 76px #161f64, 1417px 1186px #161f64, 909px 1580px #161f64,
    1913px 762px #161f64, 204px 1143px #161f64, 1998px 1057px #161f64, 1468px 1301px #161f64,
    144px 1676px #161f64, 21px 1601px #161f64, 382px 1362px #161f64, 912px 753px #161f64,
    1488px 1405px #161f64, 802px 156px #161f64, 174px 550px #161f64, 338px 1366px #161f64,
    1197px 774px #161f64, 602px 486px #161f64, 682px 1877px #161f64, 348px 1503px #161f64,
    407px 1139px #161f64, 950px 1400px #161f64, 922px 1139px #161f64, 1697px 293px #161f64,
    1238px 1281px #161f64, 1038px 1197px #161f64, 376px 1889px #161f64,
    1255px 1680px #161f64, 1008px 1316px #161f64, 1538px 1447px #161f64,
    1186px 874px #161f64, 1967px 640px #161f64, 1341px 19px #161f64, 29px 1732px #161f64,
    16px 1650px #161f64, 1021px 1075px #161f64, 723px 424px #161f64, 1175px 41px #161f64,
    494px 1957px #161f64, 1296px 431px #161f64, 175px 1507px #161f64, 831px 121px #161f64,
    498px 1947px #161f64, 617px 880px #161f64, 240px 403px #161f64;
}

#stars3:after {
    content: '';
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 387px 1878px #161f64, 760px 1564px #161f64, 1487px 999px #161f64,
    948px 1828px #161f64, 1977px 1001px #161f64, 1284px 1963px #161f64, 656px 284px #161f64,
    1268px 1635px #161f64, 1820px 598px #161f64, 642px 1900px #161f64, 296px 57px #161f64,
    921px 1620px #161f64, 476px 1858px #161f64, 658px 613px #161f64, 1171px 1363px #161f64,
    1419px 283px #161f64, 1037px 731px #161f64, 503px 663px #161f64, 1562px 463px #161f64,
    383px 1197px #161f64, 1171px 1233px #161f64, 876px 1768px #161f64, 856px 1615px #161f64,
    1375px 1924px #161f64, 1725px 918px #161f64, 952px 119px #161f64, 768px 1212px #161f64,
    992px 1462px #161f64, 1929px 717px #161f64, 1947px 755px #161f64, 1818px 1123px #161f64,
    1896px 1672px #161f64, 460px 198px #161f64, 256px 271px #161f64, 752px 544px #161f64,
    1222px 1859px #161f64, 1851px 443px #161f64, 313px 1858px #161f64, 709px 446px #161f64,
    1546px 697px #161f64, 674px 1155px #161f64, 1112px 130px #161f64, 355px 1790px #161f64,
    1496px 974px #161f64, 1696px 480px #161f64, 1316px 1265px #161f64, 1645px 1063px #161f64,
    1182px 237px #161f64, 427px 1582px #161f64, 859px 253px #161f64, 458px 939px #161f64,
    1517px 1644px #161f64, 1943px 60px #161f64, 212px 1650px #161f64, 966px 1786px #161f64,
    473px 712px #161f64, 130px 76px #161f64, 1417px 1186px #161f64, 909px 1580px #161f64,
    1913px 762px #161f64, 204px 1143px #161f64, 1998px 1057px #161f64, 1468px 1301px #161f64,
    144px 1676px #161f64, 21px 1601px #161f64, 382px 1362px #161f64, 912px 753px #161f64,
    1488px 1405px #161f64, 802px 156px #161f64, 174px 550px #161f64, 338px 1366px #161f64,
    1197px 774px #161f64, 602px 486px #161f64, 682px 1877px #161f64, 348px 1503px #161f64,
    407px 1139px #161f64, 950px 1400px #161f64, 922px 1139px #161f64, 1697px 293px #161f64,
    1238px 1281px #161f64, 1038px 1197px #161f64, 376px 1889px #161f64,
    1255px 1680px #161f64, 1008px 1316px #161f64, 1538px 1447px #161f64,
    1186px 874px #161f64, 1967px 640px #161f64, 1341px 19px #161f64, 29px 1732px #161f64,
    16px 1650px #161f64, 1021px 1075px #161f64, 723px 424px #161f64, 1175px 41px #161f64,
    494px 1957px #161f64, 1296px 431px #161f64, 175px 1507px #161f64, 831px 121px #161f64,
    498px 1947px #161f64, 617px 880px #161f64, 240px 403px #161f64;
}


@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}

