/*left side of hero*/
.leftHero {
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
}

.lesserWidth {
  animation: lessen 1s forwards;
}

@keyframes lessen {
  0% {
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  100% {
    left: -15%;
    width: 0%;
    height: 0%;
    opacity: 0;
  }
}

.theMessage {
  width: 80%;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.zoomOut {
  animation: tupaZoom 0.5s forwards;
}

@keyframes tupaZoom {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*text content and button*/
.missionStatement {
  font-weight: 400;
  color: var(--deep-blue);
}

/*bold words in hero*/
#engaging {
  padding: 5px;
  border-radius: 10px;
  background: var(--velvet-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
  font-weight: 900;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
}

/*stand out button*/
.standOut {
  font-size: 1.5rem;
  background-color: var(--deep-blue);
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-style: italic;
  align-self: flex-end;
  margin-right: 10%;
  margin-top: 5%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  transition: 0.5s;
}

.standOut:hover {
  transform: translateY(-3px);
  background-color: var(--velvet-blue);
}

.standOut div {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.standOut div:after {
  content: "👉";
  position: absolute;
  opacity: 0;
  top: -3px;
  transition: 0.5s;
}

.standOut:hover div {
  padding-right: 30px;
}

.standOut:hover div:after {
  opacity: 1;
}

#stand {
  -webkit-text-stroke: 0.5px white;
  color: transparent;
}

/*Media Query for large screens*/
@media only screen and (min-width: 1000px) {
  .leftHero {
    width: 50%;
  }

  @keyframes lessen {
    0% {
      width: 50%;
      opacity: 1;
    }
    100% {
      width: 30%;
      margin-right: 10%;
      opacity: 1;
    }
  }

  .normalWidth {
    animation: normalize 1s forwards;
  }

  @keyframes normalize {
    0% {
      width: 30%;
      margin-right: 10%;
      opacity: 1;
    }
    100% {
      width: 50%;
      opacity: 1;
    }
  }

  .theMessage {
    width: 80%;
    margin-top: 30%;
    margin-left: auto;
    margin-right: 0;
  }

  .zoomOut {
    animation: tupaZoom 1s forwards;
  }

  @keyframes tupaZoom {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(0.6);
    }
  }

  .zoomIn {
    animation: rudishaZoom 1s forwards;
  }

  @keyframes rudishaZoom {
    0% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1);
    }
  }
}
