.comingSoonScaffolding {
  /*the text content and everything else at the fore*/
  top: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*the navbar up top*/
header.landingHeader {
  max-height: 10%;
  height: 7vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.meCvLogo {
  max-height: 100%;
  padding-left: 2vw;
  padding-top: 2vh;
}

.socialIcons{
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  padding-right: 2vw;
  padding-top: 2vh;
}

.iconContainer{
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

#icon{
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  transition: 0.3s;
}

#icon:hover{
  transform: scale(1.2);
  filter: drop-shadow(0px 0px 5px var(--light-purple))
}

/*hero section of the landing*/
section#heroSection {
  position: relative;
  max-height: 93%;
  height: 93vh;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}




@media only screen and (min-width: 1000px) {
  .comingSoonScaffolding {
    width: 1280px;
    height: 800px;
  }

  /*hero section of the landing*/
  section#heroSection {
    position: relative;
    max-height: 93%;
    height: 93vh;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
  }

}
