.loadingProgress{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 28;
}

.logoToSpin{
    max-width: 100px;
    max-height: 100px;
    animation: spinAround 5s linear infinite;    
}

@keyframes spinAround {
  0% {
    /* transform: rotate(0deg); */
    filter: hue-rotate(0deg);
  }
  100% {
    /* transform: rotate(360deg); */
    filter: hue-rotate(360deg);
  }
}