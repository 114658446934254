.backDrop {
  border-radius: 10px;
  background-color: #fff;
}

.message-icon {
  background-image: url(../../assets/mail.png);
  height: 32px;
  width: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 10px;
}

.textBody {
  color: black;
  font-family: helvetica;
  font-weight: 600;
}
