.svgContainer {
  position: absolute;
  max-width: 100%;
  width: 751px;
}

@media only screen and (min-width: 1000px) {
  .svgContainer {
    position: absolute;
    max-width: 100%;
    width: 751px;
    top: -5%;
    left: -10%;
  }
}
