.formContainer {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  width: 80%;
  top: 5%;
  transform: translateX(12.5%);
  border-radius: 20px;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid var(--velvet-blue);
  filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.1));
}

.formPrompt {
  padding-top: 10px;
  max-width: 90%;
  font-weight: 400;
  color: var(--deep-blue);
  margin-bottom: 10px;
}

.formPrompt > #engaging {
  font-size: 1rem;
}

.formRow {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 10px 10px 10px;
}

.fnLnUname,
.telEmail,
.passPic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.passPic {
  flex-grow: 1.5;
}

.signup-profile-pic__container {
  position: relative;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
  width: 100px;
  border-radius: 50%;
}

.signup-profile-pic {
  object-fit: cover;
  max-width: 100%;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  border-radius: 50%;
  border: 1px solid var(--velvet-blue);
}

.add-picture-icon {
  position: absolute;
  top: 0;
  right: 10px;
  color: var(--blue-purple);
  background: white;
  cursor: pointer;
  z-index: 99;
}

#imageUploadPrompt {
  font-size: 0.7rem;
  font-weight: 400;
  color: var(--deep-blue);
}

@media only screen and (min-width: 1000px) {
  .formContainer {
    transform: translateX(0%);
    background-color: var(--actual-white);
    max-height: 50%;
    top: 15%;
    left: -5%;
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.5));
  }

  .formPrompt {
    margin-bottom: 5px;
  }

  .formRow {
    flex-direction: row;
  }

  .fnLnUname,
  .telEmail,
  .passPic {
    flex-direction: row;
    gap: 10px;
  }
}
