.sCircles{
    position: absolute;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    left: -7.5%;
}

.circlesThemselves{
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
}