#rightHeroContainer {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theForm {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.dontShow {
  animation: getLost 1s forwards;
}

@keyframes getLost {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.6);
  }
}

.appear {
  animation: comeOn 1s forwards;
}

@keyframes comeOn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }

  50%{
    opacity: 0;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.laptopImage {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.actualLaptopImage {
  display: none;
}

.redCross {
    display: none;
  }

  .stressCv {
    display: none;
  }
  .radiation {
    display: none;
  }
  

@media only screen and (min-width: 1000px) {
  #rightHeroContainer {
    max-width: 50%;
    width: 70%;
  }

  .actualLaptopImage {
    position: absolute;
    max-width: 100%;
    width: 751px;
    top: 20%;
    left: 0;
    display: block;
  }
  .zoomed {
    animation: zoomHapo 1s forwards;
  }
  @keyframes zoomHapo {
    0% {
    }
    100% {
      transform: scale(1.4);
      left: -15%;
    }
  }
  .unzoom {
    animation: toaZoom 1s forwards;
  }
  @keyframes toaZoom {
    0% {
      transform: scale(1.4);
      left: -15%;
    }
    100% {
      transform: scale(1);
      left: 0;
    }
  }

  .redCross {
    position: absolute;
    display: block;
    max-width: 100%;
    width: 751px;
    top: -5%;
    left: 0;
    transform: scale(0.3) rotateZ(15deg);
    opacity: 0;
    transition: 0.4s;
  }
   .redCross.appear {
    opacity: 1;
  }

  .stressCv {
    display: block;
    position: absolute;
    max-width: 100%;
    width: 751px;
    top: 10%;
    left: 0;
    filter: drop-shadow(0 0 10px rgba(255, 203, 203, 0.7));
  }

  .radiation {
    display: block;
    position: absolute;
    max-width: 100%;
    width: 751px;
    margin-top: 8%;
    top: 10%;
    left: 10%;
    filter: drop-shadow(0 0 10px rgba(255, 203, 203, 0.7));
  }

  .vanish {
    animation: potea 1s forwards;
  }

  @keyframes potea {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(50px);
    }
  }

  .vanish-reverse {
    animation: tokea 1s forwards;
  }

  @keyframes tokea {
    0% {
      opacity: 0;
      transform: translateX(50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

